<template>
  <v-row>
    <v-col cols="11" md="6"> {{ value.index }}. {{ value.target }} </v-col>
    <v-col cols="1" class="d-flex d-md-none">
      <span @click="contentExpanded = !contentExpanded" class="expandable">
        <v-icon v-show="contentExpanded">mdi-chevron-up</v-icon>
        <v-icon v-show="!contentExpanded">mdi-chevron-down</v-icon>
      </span>
    </v-col>

    <v-col cols="12" md="2"> {{ $d(new Date(value.date), 'date') }} </v-col>

    <v-col cols="12" md="2">
      <template v-if="statusIncluded">
        <v-select
          v-if="canChangeStatus"
          dense
          outlined
          v-model="status"
          :items="targetStatusItems"
          @change="saveTarget"
          hide-details
        />
        <template v-else>
          {{ $t(`targets.targetStatus.${value.status}`) }}
        </template>
      </template>
    </v-col>

    <v-spacer></v-spacer>

    <v-col cols="12" md="2" class="text-md-right">
      <v-tooltip v-if="!readOnly" bottom>
        <target-item-dialog
          v-model="value"
          :visible="dialogVisible"
          @close="dialogVisible = false"
          @save="updateTarget($event)"
        />
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-btn
              small
              text
              v-bind="attrs"
              v-on="on"
              @click="dialogVisible = true"
            >
              <v-icon> mdi-pencil-outline </v-icon>
            </v-btn>
          </span>
        </template>
        <span>{{ $t('edit') }}</span>
      </v-tooltip>

      <template v-if="canDelete && !readOnly">
        <template v-if="value.copyOf">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                text
                v-on="on"
                v-bind="attrs"
                @click="deleteTarget(value)"
              >
                <v-icon>mdi-minus-box-multiple </v-icon>
              </v-btn>
            </template>
            <span> {{ $t('targets.copyTargetRevert') }}</span>
          </v-tooltip>
        </template>

        <template v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                text
                v-on="on"
                v-bind="attrs"
                @click="deleteTarget(value)"
              >
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>
        </template>
      </template>

      <v-tooltip bottom v-if="canCopy && !comparisonOnly">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small text v-bind="attrs" v-on="on" @click="copyTarget(value)">
            <v-icon>mdi-plus-box-multiple</v-icon>
          </v-btn>
        </template>
        <span> {{ $t('targets.copyTarget') }} </span>
      </v-tooltip>

      <v-tooltip bottom v-if="isArchived">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small text v-bind="attrs" v-on="on" @click="resetTargetStatus">
            <v-icon> mdi-package-up </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('targets.reopen') }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!commentsDisabled">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small text v-bind="attrs" v-on="on" @click="showComments">
            <v-icon> mdi-message-text </v-icon> {{ value.comments.length }}
          </v-btn>
        </template>
        <span>{{ $t('targets.comment') }}</span>
      </v-tooltip>

      <span
        @click="contentExpanded = !contentExpanded"
        class="expandable d-none d-md-inline"
      >
        <v-icon v-show="contentExpanded">mdi-chevron-up</v-icon>
        <v-icon v-show="!contentExpanded">mdi-chevron-down</v-icon>
      </span>
    </v-col>

    <v-expand-transition>
      <v-col cols="12" v-show="contentExpanded" class="expanded-content">
        <v-row>
          <v-col cols="12">
            <p class="ma-0 muted">{{ $tc('targets.subtargets', 2) }}</p>
          </v-col>
        </v-row>
        <v-treeview
          v-if="
            subtargetsAsTreeviewNodes && subtargetsAsTreeviewNodes.length > 0
          "
          :items="subtargetsAsTreeviewNodes"
          :open-all="true"
        >
          <!-- <template v-slot:label="{ item }">
            <span v-if="item && item.id > 0"> {{ item.name }}</span>
            <v-alert v-else text dense type="info">
              {{ item.name }}
            </v-alert>
          </template> -->
        </v-treeview>
        <div v-else>
          <v-row>
            <v-col cols="12">
              <v-alert text type="info">
                {{ $t('targets.noSubtargets') }}
              </v-alert>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-expand-transition>
    <v-expand-transition v-if="!commentsDisabled">
      <v-col
        cols="12"
        v-show="contentExpanded"
        class="expanded-content"
        ref="comments"
      >
        <comments-table
          v-model="value.comments"
          @save="saveTarget"
          :readOnly="readOnly"
          :canComment="canComment"
        />
      </v-col>
    </v-expand-transition>
  </v-row>
</template>

<script>
import commentsTable from '@/components/feedbacks/targets/comments-table';
import targetItemDialog from '@/components/forms/questionnaire/form-elements/targets/new-targets/dialog.vue';
import { targetEnums } from '@/enums/targets.js';

import { v4 as uuid } from 'uuid';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: () => true
    },

    canCopy: {
      type: Boolean,
      required: false,
      default: false
    },

    canDelete: {
      type: Boolean,
      required: false,
      default: false
    },

    canChangeStatus: {
      type: Boolean,
      required: false,
      default: false
    },

    canComment: {
      type: Boolean,
      required: false,
      default: () => true
    },

    commentsDisabled: {
      type: Boolean,
      required: false,
      default: false
    },

    isArchived: {
      type: Boolean,
      required: false,
      default: () => false
    },

    statusIncluded: {
      type: Boolean,
      required: false,
      default: true
    },

    expanded: {
      type: Boolean,
      required: false,
      default: false
    },

    comparisonOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    targetStatusItems() {
      return Object.values(this.targetStatus).map((x) => ({
        text: this.$t(`targets.targetStatus.${x}`),
        value: x.toString()
      }));
    },

    sortedSubtargets() {
      // sort by index
      const sorted = [...this.value.subtargets].sort(
        (x, y) => x.index - y.index
      );
      // sort actions of each subtarget by index
      sorted.forEach((s) => s.actions.sort((x, y) => x.index - y.index));
      return sorted;
    },

    subtargetsAsTreeviewNodes() {
      const nodes = this.sortedSubtargets.map((x) => ({
        id: x.index,
        name: `${this.value.index}.${x.index}:\t${x.target}`,
        children: x.actions.map((y) => ({
          id: `${this.value.index}.${x.index}.${y.index}`,
          name: `${this.$tc('targets.actions', 1)} ${this.value.index}.${
            x.index
          }.${y.index}:\t${y.target}`
        }))
      }));
      nodes.forEach(
        (x) =>
          (x.children =
            x.children.length > 0
              ? x.children
              : [
                  {
                    id: 0,
                    name: this.$t('targets.noActions')
                  }
                ])
      );
      return nodes;
    }
  },

  data() {
    return {
      targetStatus: targetEnums.targetStatus,
      contentExpanded: false,
      dialogVisible: false,
      status: this.value.status.toString()
    };
  },

  mounted() {
    this.contentExpanded = this.expanded;
  },

  methods: {
    copyTarget(item) {
      this.$emit('copyTarget', this.createCopy(item));
    },

    createCopy(item) {
      const copy = JSON.parse(JSON.stringify(item));
      copy.copyOf = item.id;
      copy.id = uuid();
      copy.index = 0;
      copy.comments.forEach((x) => (x.id = uuid()));
      copy.subtargets.forEach((x) => {
        x.id = uuid();
        x.actions.forEach((y) => (y.id = uuid()));
      });
      return copy;
    },

    deleteTarget(item) {
      this.$emit('deleteTarget', item);
    },

    async showComments() {
      const el = this.$refs.comments;
      const waitForExpansion = this.contentExpanded ? 1 : 10;
      this.contentExpanded = true;
      for (var i = 0; i < waitForExpansion; i++) {
        // Opening the expansion panel takes some time, to ensure smooth behavior
        // we stagger several scrolling intervals over the opening time
        setTimeout(() => el.scrollIntoView({ behavior: 'smooth' }), i * 30);
      }
    },

    updateTarget(targetData) {
      this.value.target = targetData.target;
      this.value.date = targetData.date;
      this.value.subtargets = targetData.subtargets ?? [];
      this.saveTarget();
    },

    saveTarget() {
      this.value.status = this.status;
      this.$emit('saveTarget', this.value);
    },

    resetTargetStatus() {
      this.status = this.targetStatus.OPEN;
      this.saveTarget();
    }
  },

  components: {
    commentsTable,
    targetItemDialog
  }
};
</script>

<style lang="scss" scoped>
.overview-body .row .expandable:hover {
  cursor: pointer;
}

.overview-body .row.table-row-divider:hover {
  background: none;
}

.table .row.table-row-divider {
  margin-top: 0;
}

.table .row.table-row-divider > div {
  padding-left: 0;
  padding-right: 0;
  padding: 0;
}

.expanded-content {
  background-color: var(--element-background-secondary);

  .row {
    align-items: top;
  }

  .row:last-of-type:after {
    border-bottom: none;
  }
}

.v-treeview {
  ::v-deep .v-treeview-node__label {
    white-space: normal;
  }

  ::v-deep .v-treeview-node__root {
    min-height: 0;
    padding-bottom: 12px;
  }
}
</style>
