<template>
  <div>
    <comment-dialog
      :visible="commentDialogVisible"
      @save="newComment($event)"
      @close="commentDialogVisible = false"
    />
    <br />
    <v-row class="d-none d-md-flex">
      <v-col cols="8" class="ma-0 muted">
        {{ $t('targets.comment') }}
      </v-col>

      <v-col cols="2" class="ma-0 muted"> {{ $t('date') }} </v-col>

      <v-col cols="2" class="ma-0 muted">
        {{ $t('targets.commentAuthor') }}
      </v-col>
    </v-row>
    <div v-if="value && value.length > 0" class="overview-body table-body">
      <br />
      <template v-for="(comment, index) in comments">
        <comment-item
          :value="comment"
          :key="`comment_table_item_${index}`"
        ></comment-item>
      </template>
    </div>
    <div v-else class="mt-4">
      <v-row>
        <v-col cols="12">
          <v-alert text type="info">
            {{ $t('feedbacks.noComment') }}
          </v-alert>
        </v-col>
      </v-row>
    </div>
    <v-col cols="12" class="text-right" v-if="canComment">
      <v-btn @click="commentDialogVisible = true">
        <v-icon>mdi-plus</v-icon>
        {{ $t('targets.newComment') }}
      </v-btn>
    </v-col>
  </div>
</template>

<script>
import commentItem from '@/components/feedbacks/targets/comment-table-item';
import commentDialog from '@/components/feedbacks/targets/dialogs/dialog-comment.vue';
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: () => true
    },

    canComment: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },

  data() {
    return {
      commentDialogVisible: false
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    }),
    comments() {
      return [...this.value].sort((x, y) => x.index - y.index);
    }
  },

  methods: {
    newComment(comment) {
      this.value.push({
        crdt: new Date(),
        userId: this.currentUser.id,
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
        index: this.value.length + 1,
        comment: comment
      });

      this.$emit('save');
    }
  },

  components: {
    commentItem,
    commentDialog
  }
};
</script>

<style scoped lang="scss">
.table {
  margin-left: -3px;
  margin-right: -3px;
}

.table strong {
  color: #000;
}

.row:after {
  display: block;
  content: ' ';
  width: 100%;
  margin-left: 1em;
  margin-right: 1em;
  border-bottom: 2px dotted rgba(0, 0, 0, 0.1);
}

.row:last-of-type:after {
  border-bottom: none;
}
</style>
