<template>
  <v-menu
    v-model="showPicker"
    :close-on-content-click="false"
    :nudge-right="0"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="formattedDate"
        :label="label"
        v-bind="$attrs"
        v-on="!readOnly && on"
        :hint="hintText"
        persistent-hint
        color="secondary"
        header-color="primary"
        :prepend-inner-icon="hasIcon ? 'mdi-calendar' : ''"
        :required="required"
        :rules="rules"
        :clearable="!readOnly"
        readonly
        single-line
      ></v-text-field>
    </template>

    <v-date-picker
      v-model="date"
      :first-day-of-week="1"
      :readonly="readOnly"
      :min="minimumDate"
      locale="de-DE"
      @input="showPicker = false"
      @change="dateChanged"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },

    label: {
      type: String,
      required: false,
      default: ''
    },

    required: {
      type: Boolean,
      required: false,
      default: false
    },

    rules: {
      type: Array,
      required: false,
      default: () => []
    },

    hasIcon: {
      type: Boolean,
      required: false,
      default: true
    },

    hint: {
      type: String,
      required: false,
      default: null
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    disablePastDates: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      date: '',
      formattedDate: '',
      showPicker: false
    };
  },

  watch: {
    date() {
      this.formatDate();
      this.$emit('input', this.date);
    },

    value() {
      this.date = this.value;
    }
  },

  mounted() {
    this.date = this.value;
  },

  computed: {
    hintText() {
      return this.hint ?? this.$t('dateFormatHint');
    },

    minimumDate() {
      return this.disablePastDates ? new Date().toISOString() : undefined;
    }
  },

  methods: {
    dateChanged() {
      setTimeout(() => {
        this.$emit('change');
      }, 500);
    },

    formatDate() {
      if (!this.date) {
        this.formattedDate = '';
        return;
      }

      const [year, month, day] = this.date.split('-');
      this.formattedDate = `${day}.${month}.${year}`;
    }
  }
};
</script>

<style></style>
